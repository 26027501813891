import React, { useEffect, useRef, useState } from "react";
import styles from "./HomePage.module.css";
import newsPlaceholder from "../../assets/images/news-placeholder.svg";
import profLogo from "./images/professionalitet-logo.svg";
import Spinner from "../../components/Spinner/Spinner";
import ContentContainer from "../../components/ContentContainer/ContentContainer";
import Partners from "../../components/Partners/Partners";
import ImportantLinks from "../../components/ImportantLinks/ImportantLinks";
import Link from "../../components/Link";
import Icon from "../../components/Icon";
import Paginator from "../../components/Paginator/Paginator";
import Block from "../../components/Block/Block";
import EduFormHeader from "../../components/EduFormHeader/EduFormHeader";
import PageType from "../../shared/PageType";
import PageId from "../../shared/PageId";
import dateTime from "../../shared/dateTime";
import useTitle from "../../shared/useTitle";
import useApiData from "../../shared/useApiData";
import { useMedia } from "use-media";
import { useSwipeable } from "react-swipeable";

/**
 * Компонент главной страницы сайта.
 *
 * @returns {Element} Контейнер страницы.
 */
export default function HomePage() {
  const pageData = useApiData("/home/");
  const isMobile = useMedia("(max-width: 576px)");
  const newsCardRef = useRef(null);

  useTitle();

  /**
   * Компонент слайдера изображений.
   *
   * @param {Object[]} items Массив элементов слайдера.
   * @returns {Element} Контейнер слайдера.
   */
  function Slider({ items }) {
    const [currentIndex, setCurrentIndex] = useState(0);
    const intervalRef = useRef(0);
    const activeItem = items[currentIndex];

    useEffect(() => {
      const id = setInterval(nextSlide, 5000);
      intervalRef.current = id;

      return () => clearInterval(id);
    }, []);

    /**
     * Компонент кнопки "Подробнее".
     *
     * @returns {Element} Элемент кнопки.
     */
    function MoreButton() {
      const url = activeItem["detailsUrl"];

      if (url) {
        const className = `${styles.sliderMoreButton} ${activeItem["isDarkColor"] ? styles.dark : styles.light}`;

        return (
          <Link className={className} url={url} isNewTab={activeItem["isNewTab"]}>
            Подробнее
          </Link>
        );
      } else {
        return null;
      }
    }

    function nextSlide() {
      setCurrentIndex(prevIndex => (prevIndex + 1) % items.length);
    }

    function prevSlide() {
      setCurrentIndex(prevIndex => (prevIndex - 1 + items.length) % items.length);
    }

    function handleClick(isNext) {
      clearInterval(intervalRef.current);
      isNext ? nextSlide() : prevSlide();
    }

    const style = {
      transform: `translateY(-${currentIndex * 100}%)`,
      transition: "transform 0.5s ease",
    };

    return (
      <section className={styles.sliderWrapper}>
        <div className={styles.sliderCarousel} style={style}>
          {items.map(({ imageUrl, title }, index) => {
            return (
              <div className={styles.slide} key={index}>
                {isMobile ? (
                  <Link url={activeItem["detailsUrl"]} isNewTab={activeItem["isNewTab"]}>
                    <img className={styles.slideImage} src={process.env.REACT_APP_BASE_URL + imageUrl} alt={title} />
                  </Link>
                ) : (
                  <img className={styles.slideImage} src={process.env.REACT_APP_BASE_URL + imageUrl} alt={title} />
                )}
              </div>
            );
          })}
        </div>
        <MoreButton/>
        {items.length > 1 && (
          <div className={styles.sliderPaginator}>
            <Paginator
              counter={false}
              toggleButtonState={false}
              upDown={true}
              currentPos={currentIndex + 1}
              endPos={items.length}
              onClick={handleClick}
            />
          </div>
        )}
      </section>
    );
  }

  /**
   * Компонент карточки новости.
   *
   * @param {string} title Заголовок новости.
   * @param {Object} data Данные новости.
   * @param {string} url Ссылка на новость.
   * @param {boolean} isNewTab Флаг, указывающий на открытие новости в новой вкладке.
   * @returns {Element} Контейнер карточки новости.
   */
  function NewsCard({ title, data, url, isNewTab }) {
    const imageUrl = data["imageUrl"];
    const date = dateTime.parseDate(data["date"]);

    return (
      <Link url={url} isNewTab={isNewTab}>
        <div className={styles.newsCard} ref={newsCardRef}>
          <div className={styles.newsCardHeader}>
            <div className={styles.newsCardDate}>{date}</div>
            <img
              className={styles.newsCardImage}
              src={(imageUrl && process.env.REACT_APP_BASE_URL + imageUrl) ?? newsPlaceholder}
              alt={title}
            />
          </div>
          <div className={styles.newsCardBody}>
            <div className={styles.newsCardTitle}>{title}</div>
            <div className={styles.newsCardArrow}>
              <Icon name={"arrow-right"} />
            </div>
          </div>
        </div>
      </Link>
    );
  }

  /**
   * Компонент блока новостей.
   *
   * @param {Object[]} items Массив элементов новостей.
   * @returns {Element} Контейнер блока новостей.
   */
  function News({ items }) {
    const count = items.length;
    const scrollAmount = isMobile ? 1 : 2;
    const maxSteps = Math.ceil(count / scrollAmount);
    const [currentStep, setCurrentStep] = useState(1);
    const [translateX, setTranslateX] = useState(0);

    function handlePaginator(isNext) {
      const cardWidth = newsCardRef.current.offsetWidth;
      const gap = 24;
      const scrollSize = (cardWidth * scrollAmount) + (gap * scrollAmount);

      if (isNext) {
        const maxTranslateX = -(maxSteps - 1) * scrollSize;

        if (translateX > maxTranslateX) {
          setTranslateX(translateX - scrollSize);
          setCurrentStep(currentStep + 1);
        }
      } else {
        if (translateX < 0) {
          setTranslateX(translateX + scrollSize);
          setCurrentStep(currentStep - 1);
        }
      }
    }

    const handleSwipe = useSwipeable({
      onSwipedLeft: () => handlePaginator(true),
      onSwipedRight: () => handlePaginator(false),
    });
    const style = {
      transform: `translateX(${translateX}px)`,
      transition: "transform 0.5s ease",
    }

    function Nav() {
      return (
        <>
          {count > 2 && (
            <Paginator
              toggleButtonState={true}
              counter={true}
              currentPos={currentStep}
              endPos={maxSteps}
              onClick={handlePaginator}
            />
          )}
        </>
      );
    }

    return (
      <Block className={styles.newsWrapper}>
        <div className={styles.newsHeader}>
          <div className={styles.newsHeaderLeft}>
            <h2 className={"title-2"}>Новости</h2>
            <Link className={styles.allNewsLink} url={PageType.NEWS}>Показать все</Link>
          </div>
          {!isMobile && <Nav />}
        </div>
        <div className={styles.newsBody} style={style} {...handleSwipe}>
          {items?.map((props, index) => {
            return <NewsCard {...props} key={index} />;
          })}
        </div>
        {isMobile && (
          <div className={styles.newsNavWrapper}>
            <Nav />
          </div>
        )}
      </Block>
    );
  }

  /**
   * Компонент блока образовательных программ.
   *
   * @param {Object[]} forms Массив элементов форм обучения.
   * @returns {Element} Контейнер блока образовательных программ.
   */
  function Education({ forms }) {
    /**
     * Компонент для отображения формы обучения.
     *
     * @param {string} name Название формы обучения.
     * @param {string} education Образование.
     * @param {Object[]} programs Массив образовательных программ.
     * @returns {Element} Контейнер формы обучения.
     */
    function Form({ name, education, programs }) {
      function Grid() {
        function Item({ title, id }) {
          const url = `/${PageId.APPLICANTS}/#program${id}`;

          return <Link url={url} className={styles.programGridItem}>{title}</Link>;
        }

        return (
          <div className={styles.programGrid}>
            {programs.map((props, key) => {
              return <Item {...props} key={key} />;
            })}
          </div>
        );
      }

      return (
        <div>
          <EduFormHeader form={name} education={education} />
          <Grid />
        </div>
      );
    }

    const aboutUrl = `/${PageId.APPLICANTS}#programs`;

    return (
      <Block>
        <div className={styles.programsHeader}>
          <h3 className={"title-2"}>Образовательные программы</h3>
          <Link url={aboutUrl}>Подробнее о всех направлениях</Link>
        </div>
        <div className={styles.programsBody}>
          {forms.map((props, key) => {
            return <Form {...props} key={key} />;
          })}
        </div>
        <Link className={styles.aboutProgramsButton} url={aboutUrl}>Подробнее о всех направлениях</Link>
      </Block>
    );
  }

  /**
   * Компонент блока проектов.
   *
   * @returns {Element} Элемент контейнера проектов.
   */
  function Projects() {
    function Prof() {
      return (
        <Block className={styles.profContainer}>
          <img src={profLogo} alt={"Профессионалитет"} />
          <div className={styles.profAbout}>
            <div className={styles.profText}>
              СПбМРК — участник федерального проекта «Профессионалитет»
            </div>
            <Link className={styles.profLink} url={"https://япроф.рф"} isNewTab={true}>
              Сайт проекта
              <Icon name={"arrow-up-right"} size={16} />
            </Link>
          </div>
        </Block>
      );
    }

    function Platforms() {
      function Card({ icon, text }) {
        return (
          <div className={styles.platformsCard}>
            <div className={styles.platformsCardBody}>
              <div className={styles.platformsCardIcon}>
                <Icon name={icon} />
              </div>
              <div className={styles.platformsCardText}>{text}</div>
            </div>
          </div>
        );
      }

      return (
        <Block>
          <div className={styles.platformsText}>
            На базе кластера «Индустриальная цифровая аквакультура» с учётом технологических процессов рыболовства и
            аквакультуры создаются четыре площадки
          </div>
          <div className={styles.platformsGrid}>
            <Card
              icon={"ship"}
              text={"Эксплуатационно-техническая площадка судового оборудования, холодильных установок и организации перевозок"}
            />
            <Card icon={"gear"} text={"Площадка технологических процессов обработки водных биоресурсов"} />
            <Card icon={"shrimp"} text={"Площадка рыбоводства и аквакультуры"} />
            <Card icon={"robot"} text={"Площадка цифровых технологий и роботизированных систем"} />
          </div>
        </Block>
      );
    }

    return (
      <section className={styles.projectsWrapper}>
        <Prof />
        <Platforms />
      </section>
    );
  }

  /*function renderComponentIfDataExists(data, Component, propsKey) {
    return data?.length > 0 && <Component {...{ [propsKey]: data }} />;
  }*/

  return (
    <>
      {!pageData ? (
        <Spinner />
      ) : (
        <ContentContainer>
          {pageData["slider"]?.length > 0 && <Slider items={pageData["slider"]} />}
          {pageData["importantLinks"]?.length > 0 && <ImportantLinks items={pageData["importantLinks"]} />}
          {pageData["news"]?.length > 0 && <News items={pageData["news"]} />}
          {pageData["forms"]?.length > 0 && <Education forms={pageData["forms"]} />}
          <Projects />
          {pageData["partners"]?.length > 0 && <Partners items={pageData["partners"]} />}
        </ContentContainer>
      )}
    </>
  );
}