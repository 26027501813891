import React from "react";
import styles from "./Paginator.module.css";
import Icon from "../Icon";

/**
 * Компонент пагинатора.
 *
 * @param {Object} props Свойства компонента.
 * @param {number} props.currentPos Текущая позиция.
 * @param {number} props.endPos Конечная позиция.
 * @param {boolean} props.toggleButtonState Флаг, указывающий на изменение состояния кнопок.
 * @param {boolean} props.upDown Флаг, указывающий на режим прокрутки вверх-вних.
 * @param {boolean} props.ounter Флаг, указывающий на видимость счётчика.
 * @param {Function} props.onClick Обработчик клика на кнопку.
 * @returns {Element} Контейнер пагинатора.
 */
export default function Paginator(props) {
  function Button({ isNext, isEnabled }) {
    let className = styles.button;

    if (props.upDown) {
      className += " " + styles.upDown;
    }

    const handleClick = () => props.onClick(isNext);

    return (
      <button className={className} onClick={handleClick} disabled={!isEnabled}>
        <Icon className={styles.buttonIcon} name={isNext ? "chevron-right" : "chevron-left"} />
      </button>
    );
  }

  function Counter() {
    const range = props.currentPos + "/" + props.endPos;

    return <div className={styles.counter}>{range}</div>;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <Button isNext={false} isEnabled={props.toggleButtonState ? props.currentPos > 1 : true} />
        {props.counter ? <Counter /> : <div className={styles.space} />}
        <Button isNext={true} isEnabled={props.toggleButtonState ? props.currentPos !== props.endPos : true} />
      </div>
    </div>
  );
}