import React from "react";
import MasterPage from "./MasterPage/MasterPage";
import PageType from "../shared/PageType";
import { useParams } from "react-router-dom";

export default function Page() {
  const { id } = useParams();

  return (
    <>
      {id && (
        <MasterPage type={PageType.PAGE} id={id} />
      )}
    </>
  );
}