import React from "react";
import styles from "./ImportantLinks.module.css";
import Link from "../Link";

/**
 * Компонент баннера важной информации.
 *
 * @param {Object} props Свойства компонента.
 * @param {Object[]} items Массив элементов баннера.
 * @returns {Element} Контейнер баннера.
 */
export default function ImportantLinks({ items }) {
  function Links() {
    function Item({ index }) {
      const style = index === 0 ? styles.primary : styles.secondary;
      const className = `${styles.link} ${style}`;
      const item = items[index];

      return (
        <Link className={className} url={item["url"]} isNewTab={item["isNewTab"]}>
          {item["text"]}
        </Link>
      );
    }

    return (
      <>
        {items.length === 1 && (
          <div className={styles.row}>
            <Item index={0} />
          </div>
        )}
        {items.length === 2 && (
          <div className={styles.row}>
            <Item index={0} />
            <Item index={1} />
          </div>
        )}
        {items.length === 3 && (
          <>
            <div className={styles.row}>
              <Item index={0} />
            </div>
            <div className={styles.row}>
              <Item index={1} />
              <Item index={2} />
            </div>
          </>
        )}
      </>
    );
  }

  return (
    <section className={styles.wrapper}>
      {/*<div className={styles.textColumn}>
        <Icon name={"circle-exclamation"} />
        <div className={styles.infoText}>Важная информация</div>
      </div>*/}
      <div className={styles.linksColumn}>
        <Links />
      </div>
    </section>
  );
}