import { format } from "date-fns";
import { ru } from "date-fns/locale";

/**
 * Объект для работы с датой и временем в формате ISO 8601.
 */
const dateTime = {
  options: {
    locale: ru
  },

  /**
   * Добавляет ведущий ноль к числу, если оно меньше 10.
   *
   * @param {number} number Число, к которому нужно добавить ведущий ноль.
   * @returns {string} Строка с нулём либо с самим числом.
   */
  addLeadingZero(number) {
    return number.toString().padStart(2, "0");
  },

  /**
   * Извлекает дату с числом дня месяца, числом месяца и годом.
   *
   * @param {string} value Значение даты, которое необходимо разобрать.
   * @returns {string} Строка с датой в формате ГГ.ММ.ДДДД. Например, 02.08.1993.
   */
  parseDate(value) {
    const dateObj = new Date(value);
    const day = this.addLeadingZero(dateObj.getDate());
    const month = this.addLeadingZero(dateObj.getMonth() + 1);
    const year = dateObj.getFullYear();

    return `${day}.${month}.${year}`;
  },

  /**
   * Извлекает полную дату с названием дня недели.
   *
   * @param {string} value Значение даты, которое необходимо разобрать.
   * @returns {string} Строка с датой в формате `[день месяца] [название месяца] [год г.] ([день недели])`. Например,
   * "2 августа 1993 г. (понедельник)".
   */
  parseFullDateWeekday(value) {
    const dateObj = new Date(value);

    return format(dateObj, "d MMMM yyyy г. (EEEE)", this.options);
  },

  /**
   * Извлекает время и полную дату с названием месяца.
   *
   * @param {string} value Значение даты, которое необходимо разобрать.
   * @returns {string} Строка с датой в формате `[часы:минуты], [день месяца] [название месяца] [год]`. Например,
   * "12:00, 5 марта 1973".
   */
  parseTimeFullDate(value) {
    const dateObj = new Date(value);

    return format(dateObj, "HH:mm, d MMMM yyyy", this.options);
  }
}

export default dateTime;