import React, { useState } from "react";
import styles from "./StudentsPage.module.css";
import PageType from "../../shared/PageType";
import PageId from "../../shared/PageId";
import dateTime from "../../shared/dateTime";
import MasterPage from "../MasterPage/MasterPage";
import Block from "../../components/Block/Block";
import Icon from "../../components/Icon";
import Attachment from "../../components/Attachment/Attachment";

/**
 * Компонент страницы "Курсантам".
 *
 * @returns {Element} Контейнер страницы.
 */
export default function StudentsPage() {
  const [data, setData] = useState(null);

  function handleReceiveData(data) {
    setData(data);
  }

  function Timetable({ semester, timetableFileUrl, updateDate, updateFileUrl }) {
    function Card({ title, date, url, isChanges }) {
      const className = `${styles.timetableCard} ${isChanges ? styles.blueBackground : styles.grayBackground}`;
      const iconClass = isChanges ? styles.blueIcon : styles.grayIcon;
      const iconName = isChanges ? "arrows-rotate" : "table";

      return (
        <div className={className}>
          <div className={styles.timetableCardIcon}>
            <Icon className={iconClass} name={iconName} />
          </div>
          <div className={styles.timetableCardText}>
            <div className={styles.timetableCardTitle}>{title}</div>
            <Attachment name={date} url={url} />
          </div>
        </div>
      );
    }

    return (
      <Block>
        <article id={"timetable"}>
          <h2 className={"title-2"}>Расписание занятий</h2>
          <div className={styles.timetable}>
            <Card title={"Изменения в расписании"} date={updateDate} url={updateFileUrl} isChanges={true} />
            <Card title={"Расписание занятий"} date={semester} url={timetableFileUrl} isChanges={false} />
          </div>
        </article>
      </Block>
    );
  }

  return (
    <MasterPage type={PageType.CATEGORY} id={PageId.STUDENTS} onReceiveData={handleReceiveData}>
      {data && (
        <Timetable {...data["timetable"]} />
      )}
    </MasterPage>
  );
}