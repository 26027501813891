/**
 * Объект с типами страниц.
 *
 * @readonly
 * @enum {string}
 */
const PageType = {
  /**
   * Новости.
   * @type {string}
   */
  NEWS: "news",

  /**
   * Категория.
   * @type {string}
   */
  CATEGORY: "category",

  /**
   * Страница.
   * @type {string}
   */
  PAGE: "page"
}

export default PageType;