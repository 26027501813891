/**
 * Объект с идентификаторами страниц.
 *
 * @readonly
 * @enum {string}
 */
const PageId = {
  /**
   * Колледж.
   * @type {string}
   */
  ABOUT: "about",

  /**
   * Абитуриентам.
   * @type {string}
   */
  APPLICANTS: "applicants",

  /**
   * Образование.
   * @type {string}
   */
  EDUCATION: "education",

  /**
   * Курсантам.
   * @type {string}
   */
  STUDENTS: "students",

  /**
   * Контакты.
   * @type {string}
   */
  CONTACTS: "contacts"
}

export default PageId;