import React, { useState } from "react";
import styles from "./NewsPage.module.css";
import MasterPage from "../MasterPage/MasterPage";
import PageType from "../../shared/PageType";
import dateTime from "../../shared/dateTime";
import { useParams } from "react-router-dom";

/**
 * Компонент страницы новостей.
 *
 * @returns {Element} Контейнер страницы новости.
 */
export default function NewsPage() {
  const [data, setData] = useState(null);
  const { id } = useParams();

  function DateBadge() {
    const date = dateTime.parseTimeFullDate(data["date"]);

    return <div className={styles.dateBadge}>{date}</div>;
  }

  const handleReceiveData = (data) => setData(data);

  return (
    <>
      {id && (
        <MasterPage type={PageType.NEWS} id={id} beforeContent={<DateBadge />} onReceiveData={handleReceiveData} />
      )}
    </>
  );
}