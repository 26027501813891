import React from "react";
import styles from "./EduFormHeader.module.css";
import Icon from "../Icon";

export default function EduFormHeader({ form, education }) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.form}>
        <Icon name={"graduation-cap"} />
        {form}
        <span>/</span>
      </div>
      <span>{education}</span>
    </div>
  );
}