/*
 * Frontend-разработчик: Егоров Илья
 * Сайт: https://egoroffsoft.ru
 * Email: egoroffsoft@gmail.com
 *
 * Дата релиза: 06.05.2024
 */
import React, { createContext, useContext, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PageType from "./shared/PageType";
import PageId from "./shared/PageId";
import Header from "./components/Header/Header";
import HomePage from "./pages/HomePage/HomePage";
import Footer from "./components/Footer/Footer";
import NewsPage from "./pages/NewsPage/NewsPage";
import ContactsPage from "./pages/ContactsPage/ContactsPage";
import ApplicantsPage from "./pages/ApplicantsPage/ApplicantsPage";
import Page from "./pages/Page";
import AboutPage from "./pages/AboutPage/AboutPage";
import StudentsPage from "./pages/StudentsPage/StudentsPage";
import EducationPage from "./pages/EducationPage/EducationPage";
import NotFoundPage from "./pages/NotFoundPage/NotFoundPage";
import FullMenu from "./components/FullMenu/FullMenu";
import ToTopButton from "./components/ToTopButton/ToTopButton";
import ScrollRouter from "./components/ScrollRouter";
import NewsListPage from "./pages/NewsListPage/NewsListPage";

const AppContext = createContext(null);

export const useAppContext = () => useContext(AppContext);

export default function App() {
  const [isFullMenuVisible, setIsFullMenuVisible] = useState(false);

  useEffect(() => {
    document.body.style.overflow = isFullMenuVisible ? "hidden" : "";
  }, [isFullMenuVisible]);

  return (
    <BrowserRouter>
      <ScrollRouter />
      <AppContext.Provider value={{ isFullMenuVisible, setIsFullMenuVisible }}>
        <Header />
        <main>
          <Routes>
            <Route path={""} element={<HomePage />} index />
            <Route path={PageId.ABOUT} element={<AboutPage />} />
            <Route path={PageId.APPLICANTS} element={<ApplicantsPage />} />
            <Route path={PageId.STUDENTS} element={<StudentsPage />} />
            <Route path={PageId.EDUCATION} element={<EducationPage />} />
            <Route path={PageId.CONTACTS} element={<ContactsPage />} />
            <Route path={`${PageType.NEWS}/:page?`} element={<NewsListPage />} />
            <Route path={`${PageType.NEWS}/pages/:id`} element={<NewsPage />} />
            <Route path={`${PageType.PAGE}/:id`} element={<Page />} />
            <Route path={"*"} element={<NotFoundPage />} />
          </Routes>
        </main>
        <Footer />
        {!isFullMenuVisible && <ToTopButton />}
        {isFullMenuVisible && <FullMenu />}
      </AppContext.Provider>
    </BrowserRouter>
  );
}