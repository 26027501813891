import React from "react";
import styles from "./FullMenu.module.css";
import Link from "../Link";
import Spinner from "../Spinner/Spinner";
import useApiData from "../../shared/useApiData";
import { useAppContext } from "../../App";

/**
 * Компонент полноэкранного меню.
 *
 * @returns {Element} Контейнер меню.
 */
export default function FullMenu()  {
  const categories = useApiData("/pages/categories/");
  const { setIsFullMenuVisible } = useAppContext();

  function Category({ title, url, pages }) {
    const handleClick = () => setIsFullMenuVisible(false);

    return (
      <div className={styles.list}>
        <Link url={url} onClick={handleClick}>
          <span className={styles.title}>{title}</span>
        </Link>
        {pages?.map(({ title, url, isNewTab }, index) => {
          return (
            <Link url={url} isNewTab={isNewTab} key={index} reload={true} onClick={handleClick}>
              <span className={styles.listItem}>{title}</span>
            </Link>
          );
        })}
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      {!categories ? (
        <Spinner />
      ) : (
        <div className={styles.container}>
          {categories?.map((props, key) => {
            return <Category {...props} key={key} />;
          })}
        </div>
      )}
    </div>
  );
}