import React from "react";
import styles from "./Warning.module.css";

/**
 * Компонент предупреждения.
 *
 * @param {string} title Текст заголовка.
 * @param {string} text Текст сообщения.
 * @returns {Element} Элемент сообщения с текстом предупреждения.
 * @constructor
 */
export default function Warning({ title, children: text }) {
  return (
    <div className={styles.wrapper}>
      {title && <div className={styles.title}>{title}</div>}
      {text}
    </div>
  );
}