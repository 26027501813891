import React from "react";
import styles from "./Partners.module.css";
import Link from "../Link";
import Block from "../Block/Block";

export default function Partners({ items }) {
  function Slider({ moveRight }) {
    const className = `${styles.slider} ${moveRight ? styles.animMoveRight : styles.animMoveLeft}`;

    function Images() {
      return (
        <span>
          {items.map(({ imageUrl, name, url }, key) => {
            return (
              <Link url={url} isNewTab={true} key={key}>
                <img src={process.env.REACT_APP_BASE_URL + imageUrl} alt={name} />
              </Link>
            );
          })}
        </span>
      );
    }

    return (
      <div className={className}>
        <Images />
        <Images />
      </div>
    );
  }

  return (
    <Block className={styles.wrapper}>
      <h3 className={"title-2"}>Наши партнёры</h3>
      <div className={styles.container}>
        <Slider moveRight={true} />
        <Slider moveRight={false} />
      </div>
    </Block>
  );
}