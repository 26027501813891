import React from "react";
import styles from "./ContactsPage.module.css";
import PageType from "../../shared/PageType";
import PageId from "../../shared/PageId";
import MasterPage from "../MasterPage/MasterPage";
import Block from "../../components/Block/Block";
import Link from "../../components/Link";

/**
 * Компонент страницы контактов.
 *
 * @returns {Element} Контейнер страницы.
 */
export default function ContactsPage() {
  function Info() {
    function Column({ children }) {
      return <div className={styles.infoBlockColumn}>{children}</div>;
    }

    function Item({ titles, subtitle }) {
      return (
        <div className={styles.infoBlockItem}>
          {titles.map((text, key) => {
            return <div className={styles.infoBlockItemTitle} key={key}>{text}</div>;
          })}
          <div className={styles.infoBlockItemSubtitle}>{subtitle}</div>
        </div>
      );
    }

    return (
      <Block>
        <h2 className={"title-2"}>Основная информация</h2>
        <div className={styles.infoBlock}>
          <Column>
            <Item
              titles={["Лосяков Сергей Геннадьевич"]}
              subtitle={"директор филиала"}
            />
            <Item
              titles={["Понедельник с 14:00 до 15:00"]}
              subtitle={"часы приёма директора по личным вопросам"}
            />
            <Item
              titles={[<Link url={"tel:+78122346030"}>+7 (812) 234-60-30</Link>]}
              subtitle={"приёмная руководителя"}
            />
          </Column>
          <Column>
            <Item
              titles={[
                <Link url={"mailto:common@spbmrk.ru"}>common@spbmrk.ru</Link>,
                <Link url={"tel:+78122346030"}>+7 (812) 234-60-30</Link>
              ]}
              subtitle={"«СПбМРК» (филиал) ФГБОУ ВО «КГТУ»"}
            />
            <Item
              titles={[
                <Link url={"mailto:priemcom@spbmrk.ru"}>priemcom@spbmrk.ru</Link>,
                <Link url={"tel:+78122349342"}>+7 (812) 234-93-42</Link>
              ]}
              subtitle={"приёмная комиссия"}
            />
          </Column>
        </div>
      </Block>
    );
  }
  function Map() {
    //https://yandex.ru/map-widget/v1/org/sankt_peterburgskiy_morskoy_rybopromyshlenny_kolledzh/1013649456/?ll=30.280315%2C59.979912&utm_source=share&z=13
    
    return (
      <Block className={styles.map}>
        <h3 className={"title-2"}>Как нас найти?</h3>
        <iframe
          title={"Местонахождение колледжа"}
          src={"https://yandex.ru/map-widget/v1/?ll=30.290849%2C59.982698&mode=routes&rtext=59.985512%2C30.300838~59.979912%2C30.280315&rtt=pd&ruri=ymapsbm1%3A%2F%2Ftransit%2Fstop%3Fid%3Dstation__9805916~ymapsbm1%3A%2F%2Forg%3Foid%3D1013649456&z=16.21"}
          allowFullScreen={true}>
        </iframe>
      </Block>
    );
  }

  return (
    <MasterPage type={PageType.CATEGORY} id={PageId.CONTACTS}>
      <Info />
      <Map />
    </MasterPage>
  );
}