import React, { useEffect, useState } from "react";
import styles from "./ToTopButton.module.css";
import Icon from "../Icon";

/**
 * Компонент кнопки прокрутки вверх.
 *
 * @returns {Element} Элемент кнопки.
 */
export default function ToTopButton() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const type = "scroll";

    handleScroll();
    window.addEventListener(type, handleScroll);

    return () => {
      window.removeEventListener(type, handleScroll);
    };
  }, []);

  function handleScroll() {
    setIsVisible(window.scrollY > 400);
  }

  function handleClick() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  let className = styles.button;

  if (isVisible) {
    className += " " + styles.visible;
  }

  return (
    <button className={className} onClick={handleClick}>
      <Icon name={"arrow-up"} />
    </button>
  );
}