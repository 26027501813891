import React, { useEffect, useState } from "react";
import newsPlaceholder from "../../assets/images/news-placeholder.svg";
import styles from "./NewsListPage.module.css";
import MasterPage from "../MasterPage/MasterPage";
import dateTime from "../../shared/dateTime";
import Link from "../../components/Link";
import Block from "../../components/Block/Block";
import { useNavigate, useParams } from "react-router-dom";
import useApiData from "../../shared/useApiData";
import Icon from "../../components/Icon";
import Spinner from "../../components/Spinner/Spinner";
import PageType from "../../shared/PageType";

/**
 * Компонент страницы списка новостей.
 *
 * @returns {Element} Страница со списком новостей.
 */
export default function NewsListPage() {
  const { page } = useParams();
  const pageNumber = page || 1;
  const response = useApiData(`/pages/news/?page=${pageNumber}`);
  const [pageData, setPageData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    setPageData(response);
  }, [response]);

  function List({ items }) {
    function Card({ imageUrl, date, title, description, newsUrl }) {
      const formattedDate = dateTime.parseTimeFullDate(date);

      return (
        <Link className={styles.card} url={newsUrl}>
          <img src={(imageUrl && process.env.REACT_APP_BASE_URL + imageUrl) ?? newsPlaceholder} alt={title} />
          <div className={styles.cardBody}>
            <div className={styles.cardDate}>{formattedDate}</div>
            <div className={styles.cardText}>
              <span className={styles.cardTitle}>{title}</span>
              <div className={styles.cardDescription}>{description}</div>
            </div>
          </div>
        </Link>
      );
    }

    return (
      <Block className={styles.list}>
        {items?.map((item, index) => {
          return (
            <Card
              imageUrl={item["data"]["imageUrl"]}
              date={item["data"]["date"]}
              title={item["title"]}
              description={item["data"]["description"]}
              newsUrl={item["url"]}
              key={index}
            />
          );
        })}
      </Block>
    );
  }

  function Paginator({ current, next, prev }) {
    function Button({ isNext, isEnabled }) {
      function goToPage(number) {
        if (number) {
          setPageData(null);
          navigate(`/${PageType.NEWS}/${number}`);
        }
      }

      function handleClick() {
        isNext ? goToPage(next) : goToPage(prev);
      }

      return (
        <button className={styles.paginatorButton} onClick={handleClick} disabled={!isEnabled}>
          <Icon name={`caret-${isNext ? "right" : "left"}-solid`} />
        </button>
      );
    }

    function Counter() {
      return <div className={styles.paginatorCounter}>{current}</div>
    }

    return (
      <div className={styles.paginator}>
        <Button isNext={false} isEnabled={prev !== null} />
        <Counter />
        <Button isNext={true} isEnabled={next !== null} />
      </div>
    );
  }

  return (
    <MasterPage title={"Новости"}>
      {!pageData ? (
        <Spinner />
      ) : (
        <div>
          <List items={pageData["items"]} />
          <Paginator {...pageData["paginator"]} />
        </div>
      )}
    </MasterPage>
  );
}