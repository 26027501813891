import React from "react";
import styles from "./ContentImage.module.css";

/**
 * Компонент изображения контента.
 *
 * @param {string} url Ссылка на изображение.
 * @param {string} caption Подпись изображения.
 * @returns {Element} Контейнер изображения с подписью.
 */
export default function ContentImage({ url, caption }) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.background}>
        <img src={url} alt={caption} />
      </div>
      {caption !== "" && <div className={styles.caption}>{caption}</div>}
    </div>
  );
}