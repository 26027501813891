import React from "react";
import styles from "./Spinner.module.css";

export default function Spinner() {
  return (
    <div className={styles.wrapper}>
      <svg width="64" height="64" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
        <path d="M28 4C28 1.7875 29.7875 0 32 0C49.675 0 64 14.325 64 32C64 37.825 62.4375 43.3 59.7125 48C58.6125 49.9125 56.1625 50.5625 54.25 49.4625C52.3375 48.3625 51.6875 45.9125 52.7875 44C54.825 40.475 56 36.375 56 32C56 18.75 45.25 8 32 8C29.7875 8 28 6.2125 28 4Z"/>
      </svg>
    </div>
  );
}