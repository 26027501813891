import React, { useEffect, useState } from "react";
import styles from "./Header.module.css";
import { useLocation } from "react-router-dom";
import { useAppContext } from "../../App";
import Icon from "../Icon";
import Link from "../Link";
import PageId from "../../shared/PageId";

/**
 * Компонент шапки сайта.
 *
 * @returns {Element} Элемент шапки.
 */
export default function Header() {
  const { isFullMenuVisible, setIsFullMenuVisible } = useAppContext();
  const { pathname } = useLocation();
  const [activeMenuItem, setActiveMenuItem] = useState(null);

  useEffect(() => {
    setActiveMenuItem(pathname);
  }, [pathname]);

  function Logo() {
    const handleClick = () => setIsFullMenuVisible(false);

    return (
      <Link url={"/"} onClick={handleClick}>
        <div className={styles.logo} />
      </Link>
    );
  }

  function Nav() {
    function Item({ url, children }) {
      let className = styles.menuItem;

      if (activeMenuItem === url) {
        className += " " + styles.menuItemActive;
      }

      return <Link className={className} url={url}>{children}</Link>;
    }

    function Button() {
      const handleClick = () => setIsFullMenuVisible(!isFullMenuVisible);

      return (
        <button className={styles.menuButton} onClick={handleClick}>
          <Icon name={isFullMenuVisible ? "xmark-large" : "bars"} />
        </button>
      );
    }

    return (
      <div className={styles.menu}>
        {!isFullMenuVisible && (
          <nav>
            <Item url={`/${PageId.ABOUT}`}>Колледж</Item>
            <Item url={`/${PageId.APPLICANTS}`}>Абитуриентам</Item>
            <Item url={`/${PageId.STUDENTS}`}>Курсантам</Item>
            <Item url={`/${PageId.EDUCATION}`}>Образование</Item>
            <Item url={`/${PageId.CONTACTS}`}>Контакты</Item>
          </nav>
        )}
        <Button />
      </div>
    );
  }

  return (
    <header className={styles.wrapper}>
      <div className={styles.container}>
        <Logo />
        <Nav />
      </div>
    </header>
  );
}