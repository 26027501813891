import React from "react";
import { Link as RouterLink } from "react-router-dom";

/**
 * Компонент ссылки.
 *
 * @param {Object} props Свойства компонента.
 * @param {string} props.className Класс для ссылки.
 * @param {string} props.url URL, на который ссылается ссылка.
 * @param {boolean} props.isNewTab Флаг, указывающий на открытие ссылки в новой вкладке.
 * @param {boolean} props.reload Флаг, указывающий на перезагрузку документа.
 * @param {Function} props.onClick
 * @param {React.ReactNode} props.children Дочерние элементы ссылки.
 * @returns {Element} Элемент ссылки.
 */
export default function Link(props) {
  const target = props.isNewTab === true ? "_blank" : null;
  const rel = target ? "noopener noreferrer" : null;

  return (
    <RouterLink
      className={props.className}
      to={props.url}
      target={target}
      rel={rel}
      reloadDocument={props.reload ?? false}
      onClick={props.onClick}>
      {props.children}
    </RouterLink>
  );
}