import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

/**
 * Хук для выполнения запроса к API.
 *
 * @param {string|null} path Путь к конечной точке API.
 * @returns {any} Полученные данные.
 */
export default function useApiData(path) {
  const [data, setData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (path) {
      const source = axios.CancelToken.source();

      axios.get(`${process.env.REACT_APP_BASE_URL}/api${path}`, {
        cancelToken: source.token
      })
        .then(response => {
          //console.log("Получены данные:", response.data);
          setData(response.data);
        })
        .catch(error => {
          if (!axios.isCancel(error)) {
            //console.log("Ошибка запроса:", error);

            if (error.response && error.response.status === 404) {
              navigate("/404", { replace: true });
            }
          }
        });

      return () => source.cancel();
    }
  }, [path, navigate]);

  return data;
}