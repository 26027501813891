import React from "react";
import styles from "./ContentContainer.module.css";

/**
 * Компонент контейнера контента.
 *
 * @param {React.ReactNode} children Дочерние элементы контейнера.
 * @returns {Element} Контейнер контента.
 */
export default function ContentContainer({ children }) {
  return <div className={styles.wrapper}>{children}</div>;
}