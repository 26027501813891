import React from "react";
import styles from "./Table.module.css";

export default function Table({ withHeadings, content }) {
  const rows = content?.map((row, rowIndex) => {
    const cols = row.map((html, colIndex) => {
      return withHeadings && rowIndex === 0 ? (
        <th dangerouslySetInnerHTML={{ __html: html }} key={colIndex} />
      ) : (
        <td dangerouslySetInnerHTML={{ __html: html }} key={colIndex} />
      );
    });

    return <tr key={rowIndex}>{cols}</tr>;
  });

  return (
    <div className={styles.wrapper}>
      <table className={styles.table}>
        <tbody>{rows}</tbody>
      </table>
    </div>
  );
}