import React from "react";
import MasterPage from "../MasterPage/MasterPage";
import PageType from "../../shared/PageType";
import PageId from "../../shared/PageId";

/**
 * Компонент страницы образования.
 *
 * @returns {Element} Контейнер страницы.
 */
export default function EducationPage() {
  return <MasterPage type={PageType.CATEGORY} id={PageId.EDUCATION} />;
}