import { useEffect } from "react";

/**
 * Хук для установки заголовка страницы.
 *
 * @param {string} text Текст заголовка.
 */
export default function useTitle(text) {
  const defaultTitle = "Санкт-Петербургский морской рыбопромышленный колледж";

  useEffect(() => {
    document.title = text ? text + " — СПбМРК" : defaultTitle;
  }, [text]);

  return () => {
    document.title = defaultTitle;
  }
}