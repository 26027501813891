import React from "react";
import styles from "./Block.module.css";

export default function Block ({ className, title, vPadding, hPadding, children }) {
  const baseClass = styles.wrapper;
  const classes = className ? `${baseClass} ${className}` : baseClass;
  const style = {
    padding: `${vPadding}px ${hPadding}px`
  };

  return <section className={classes} style={style}>{children}</section>;
}