import React from "react";
import styles from "./NotFoundPage.module.css";
import MasterPage from "../MasterPage/MasterPage";
import Block from "../../components/Block/Block";
import Link from "../../components/Link";

/**
 * Компонент страницы 404 ошибки (страница не найдена).
 *
 * @returns {Element} Контейнер страницы.
 */
export default function NotFoundPage() {
  return (
    <MasterPage title={"Страница не найдена"}>
      <Block>
        <div className={styles.text}>Страница была удалена либо никогда не существовала</div>
        <Link className={styles.button} url={"/"}>На главную</Link>
      </Block>
    </MasterPage>
  );
}