import React from "react";
import styles from "./Breadcrumbs.module.css";
import Link from "../Link";
import Icon from "../Icon";

/**
 * Компонент навигации по сайту (хлебные крошки).
 *
 * @param {Object[]} items Массив ссылок.
 * @returns {Element} Контейнер навигации.
 */
function Breadcrumbs({ items }){
  const lastIndex = items.length - 1;

  function Item({ name, url, isActive }) {
    const className = isActive ? `${styles.item} ${styles.active}` : styles.item;

    return (
      <div className={className}>
        {url ? <Link url={url}>{name}</Link> : name}
        {!isActive && <Icon name={"chevron-right"} size={12} />}
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <Item name={"Главная"} url={"/"} isActive={false} />
      {items?.map((props, index) => {
        return <Item {...props} isActive={index === lastIndex} key={index} />;
      })}
    </div>
  );
}

export default Breadcrumbs;
