import { useLocation } from "react-router-dom";
import { useEffect } from "react";

export default function ScrollRouter() {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    if (hash === "") {
      window.scrollTo(0, 0);
    } else {
      const id = hash.replace("#", "");
      const intervalId = setInterval(() => {
        const element = document.getElementById(id);

        if (element) {
          clearInterval(intervalId);
          element.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }, 100);
    }
  }, [pathname, hash]);
}