import React from "react";
import styles from "./Footer.module.css";
import vkIcon from "./images/vk.svg";
import telegramIcon from "./images/telegram.svg";
import moodleIcon from "./images/moodle.svg";
import Icon from "../Icon";
import Link from "../Link";
import PageId from "../../shared/PageId";

/**
 * Компонент подвала сайта.
 *
 * @returns {Element} Контейнер подвала.
 */
export default function Footer() {
  function Info() {
    function Card({ title, icon, children, after }) {
      function Title({ title, icon }) {
        return (
          <div className={styles.cardTitle}>
            {icon && (
              <div>
                <Icon className={styles.cardIcon} name={icon} />
              </div>
            )}
            {title}
          </div>
        );
      }

      return (
        <div className={styles.card}>
          <div className={styles.cardInner}>
            <Title title={title} icon={icon} />
            {children}
          </div>
          {after}
        </div>
      );
    }

    function CardsColumn({ children }) {
      return <div className={styles.cardsColumn}>{children}</div>;
    }

    function ContactsRow({ children }) {
      return <div className={styles.contactsRow}>{children}</div>;
    }

    function CardItem({ children }) {
      return <div className={styles.cardItem}>{children}</div>;
    }

    function CardLink({ src, children }) {
      return <Link className={styles.cardItem} url={src}>{children}</Link>;
    }

    function Column({ children }) {
      return <div className={styles.infoColumn}>{children}</div>;
    }

    function AboutOrganizationCard() {
      function VIKONLink({ category, children }) {
        return <Link url={`/sveden/${category}/`} isNewTab={true}>{children}</Link>;
      }

      return (
        <Card title={"Сведения об образовательной организации"} icon={"building-columns"}>
          <div className={styles.infoRow}>
            <Column>
              <VIKONLink category={"common"}>Основные сведения</VIKONLink>
              <VIKONLink category={"struct"}>Структура и органы управления образовательной организацией</VIKONLink>
              <VIKONLink category={"document"}>Документы</VIKONLink>
              <VIKONLink category={"education"}>Образование</VIKONLink>
              <VIKONLink category={"eduStandarts"}>Образовательные стандарты и требования</VIKONLink>
              <VIKONLink category={"employees"}>Педагогический (научно-педагогический) состав</VIKONLink>
              <VIKONLink category={"objects"}>
                Материально-техническое обеспечение и оснащённость образовательного процесса
              </VIKONLink>
            </Column>
            <Column>
              <VIKONLink category={"grants"}>Стипендии и меры поддержки обучающихся</VIKONLink>
              <VIKONLink category={"paid_edu"}>Платные образовательные услуги</VIKONLink>
              <VIKONLink category={"budget"}>Финансово-хозяйственная деятельность</VIKONLink>
              <VIKONLink category={"vacant"}>Вакантные места для приема (перевода) обучающихся</VIKONLink>
              <VIKONLink category={"ovz"}>Доступная среда</VIKONLink>
              <VIKONLink category={"inter"}>Международное сотрудничество</VIKONLink>
              <VIKONLink category={"catering"}>Организация питания в образовательной организации</VIKONLink>
            </Column>
          </div>
        </Card>
      );
    }

    function ContactsCard() {
      return (
        <Card title={"Контакты"} icon={"location-dot"}>
          <CardItem>197022, г. Санкт-Петербург, Большая аллея, дом 22</CardItem>
          <CardLink src={"tel:+78122346030"}>+7 (812) 234-60-30</CardLink>
          <CardLink src={"mailto:common@spbmrk.ru"}>common@spbmrk.ru</CardLink>
        </Card>
      );
    }

    function AdmissionCard() {
      function ApplicantsLink() {
        return <Link className={styles.applicantsLink} url={`/${PageId.APPLICANTS}`}>Абитуриентам</Link>;
      }

      return (
        <Card title={"Приёмная комиссия"} icon={"graduation-cap"} after={<ApplicantsLink />}>
          <CardLink src={"tel:+78122349342"}>+7 (812) 234-93-42</CardLink>
          <CardLink src={"mailto:priemcom@spbmrk.ru"}>priemcom@spbmrk.ru</CardLink>
        </Card>
      );
    }

    function SocialsCard() {
      function Item({ icon, url, children }) {
        return (
          <Link className={styles.socialLink} url={url} isNewTab={true}>
            <img src={icon} alt={""} />
            {children}
            <div>
              <Icon name={"arrow-up-right"} />
            </div>
          </Link>
        );
      }

      return (
        <Card title={"Полезные ссылки"} icon={"link"}>
          <div className={styles.links}>
            <Item icon={vkIcon} url={"https://vk.com/spbmrk_professionalitet"}>
              Группа ВКонтакте
            </Item>
            <Item icon={telegramIcon} url={"https://t.me/spbmrk_professionalitet"}>
              Канал в Телеграм
            </Item>
            <Item icon={moodleIcon} url={"https://eios.spbmrk.klgtu.ru/login/"}>
              Электронная образовательная среда (ЭИОС)
            </Item>
          </div>
        </Card>
      );
    }

    return (
      <div className={styles.cards}>
        <CardsColumn>
          <AboutOrganizationCard />
        </CardsColumn>
        <CardsColumn>
          <ContactsRow>
            <ContactsCard />
            <AdmissionCard />
          </ContactsRow>
          <ContactsRow>
            <SocialsCard />
          </ContactsRow>
        </CardsColumn>
      </div>
    );
  }

  function License() {
    const baseUrl = process.env.REACT_APP_BRANCH_URL + "/about/document/license";

    return (
      <>
        <hr/>
        <div className={styles.license}>
          <span>
            <Link url={baseUrl + "/#gallery-docs-1"} isNewTab={true}>
              Выписка из реестра лицензий на осуществление образовательной деятельности регистрационный номер
              Л035-00115-39/00097158
            </Link>
          </span>
          <span>
            <Link url={baseUrl + "/#gallery-docs-2"} isNewTab={true}>
              Выписка из реестра организаций, осуществляющих образовательную деятельность по имеющим государственную
              аккредитацию образовательным программам регистрационный номер А007-00115-39/00956798
            </Link>
          </span>
        </div>
      </>
    );
  }

  function Bottom() {
    function Menu() {
      return (
        <nav className={styles.menu}>
          <Link url={`${process.env.REACT_APP_BRANCH_URL}/privacy/`} isNewTab={true}>
            Персональные данные
          </Link>
          <Link url={`${process.env.REACT_APP_BRANCH_URL}/privacy/privacy-policy/`} isNewTab={true}>
            Политика конфиденциальности
          </Link>
        </nav>
      );
    }

    function Copyright() {
      const url = `${process.env.REACT_APP_BRANCH_URL}/divisions/tsentr_informatsionnykh_tekhnologiy/`;

      return (
        <div className={styles.developer}>
          Разработано <Link url={url} isNewTab={true}>ФГБОУ ВО «КГТУ»</Link>
        </div>
      );
    }

    return (
      <>
        <hr/>
        <div className={styles.bottom}>
          <Menu/>
          <Copyright/>
        </div>
      </>
    );
  }

  return (
    <footer className={styles.wrapper}>
      <div className={styles.container}>
        <Info />
        <License />
        <Bottom />
      </div>
    </footer>
  );
}