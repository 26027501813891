import React, { useState } from "react";
import styles from "./ApplicantsPage.module.css";
import PageType from "../../shared/PageType";
import PageId from "../../shared/PageId";
import MasterPage from "../MasterPage/MasterPage";
import Icon from "../../components/Icon";
import Block from "../../components/Block/Block";
import Link from "../../components/Link";
import EduFormHeader from "../../components/EduFormHeader/EduFormHeader";

/**
 * Компонент страницы для абитуриентов.
 *
 * @returns {Element} Контейнер страницы.
 */
export default function ApplicantsPage() {
  const [pageData, setPageData] = useState(null);

  /**
   * Компонент для отображения блока открытых дверей.
   *
   * @param {string} date Дата проведения мероприятия.
   * @param {string} url Ссылка на мероприятие.
   * @returns {Element} Контейнер блока открытых дверей.
   */
  function OpenDoors({ date, url }) {
    return (
      <>
        {date && (
          <Link className={styles.openDoors} url={url}>
            <div className={styles.openDoorsIcon}>
              <Icon name={"bullhorn"} />
            </div>
            <div className={styles.openDoorsText}>
              День открытых дверей
              <span className={styles.openDoorsDate}>{date}</span>
            </div>
          </Link>
        )}
      </>
    );
  }

  /**
   * Компонент для отображения блока о работе приёмной комиссии.
   *
   * @param {string} startDate Дата начала работы.
   * @param {string[]} workingTime Массив рабочего времени.
   * @returns {Element} Контейнер блока работы приёмной комиссии.
   */
  function Admission({ startDate, workingTime }) {
    function Column({ title, children }) {
      return (
        <div className={styles.admissionTextColumn}>
          <div className={styles.admissionTextColumnTitle}>{title}</div>
          {children}
        </div>
      );
    }

    function WorkingTime() {
      return (
        <>
          {(startDate || workingTime) && (
            <Column title={"Работа приёмной комиссии"}>
              <div className={styles.workingHours}>
                {startDate && <div className={styles.workingHoursTitle}>{startDate}</div>}
                {workingTime && (
                  <div className={styles.workingHoursList}>
                    {workingTime.map((time, index) => {
                      return <div key={index}>{time}</div>;
                    })}
                  </div>
                )}
              </div>
            </Column>
          )}
        </>
      );
    }

    function PostalAddress() {
      return (
        <Column title={"Для почтовых отправлений"}>
          <div className={styles.postalList}>
            <div>Санкт-Петербургский морской рыбопромышленный колледж (филиал) ФГБОУ ВО «КГТУ»</div>
            <div>197022, Россия, г. Санкт-Петербург, Большая аллея, дом 22</div>
          </div>
        </Column>
      );
    }

    function Button() {
      const data = pageData["applicationButton"];

      return (
        <>
          {data["isVisible"] && (
            <Link className={styles.applicationButton} url={data["url"]}>
              Подать документы о приёме в электронном виде
            </Link>
          )}
        </>
      );
    }

    return (
      <div className={styles.admission}>
        <div className={styles.admissionText}>
          <WorkingTime />
          <PostalAddress />
        </div>
        <Button />
      </div>
    );
  }

  /**
   * Компонент для отображения блока важной информации.
   * Включает в себя блок дня открытых дверей и блок работы приёмной комиссии.
   *
   * @param {Object} openDoorsData Данные блока дня открытых дверей.
   * @param {Object} admissionData Данные блока работы приёмной комиссии.
   * @returns {Element} Контейнер блока важной информации.
   */
  function Important({ openDoorsData, admissionData }) {
    return (
      <section className={styles.importantInfo}>
        <OpenDoors {...openDoorsData} />
        <Admission {...admissionData} />
      </section>
    );
  }

  /**
   * Компонент для отображения блока образовательных программ и плана приёма.
   *
   * @param {Object} data Данные блока.
   * @returns {Element} Контейнер блока программ.
   */
  function Enrollment({ data }) {
    function Forms({ name, education, programs }) {
      function Card ({ id, title, fields }) {
        function Field({ name, value }) {
          return (
            <div className={styles.programCardField}>
              <span>{name}</span>
              {value}
            </div>
          );
        }

        return (
          <article className={styles.programCard} id={`program${id}`}>
            <div className={styles.programCardHeader}>{title}</div>
            <div className={styles.programCardBody}>
              {fields.map((props, key) => {
                return <Field {...props} key={key} />;
              })}
            </div>
          </article>
        );
      }

      return (
        <div>
          <EduFormHeader form={name} education={education} />
          <div className={styles.programsGrid}>
            {programs?.map((props) => {
              return <Card {...props} key={props["id"]} />;
            })}
          </div>
        </div>
      );
    }

    return (
      <Block>
        <article id={"programs"}>
          <div className={styles.programsHeader}>
            <h2 className={"title-2"}>Программы и план приёма</h2>
            {data["title"]}
          </div>
          <div className={styles.programsBody}>
            {data["forms"]?.map((props, key) => {
              return <Forms {...props} key={key}/>;
            })}
          </div>
        </article>
      </Block>
    );
  }

  const handleReceiveData = (data) => setPageData(data);
  
  return (
    <MasterPage type={PageType.CATEGORY} id={PageId.APPLICANTS} onReceiveData={handleReceiveData}>
      {pageData && (
        <>
          <Important openDoorsData={pageData["openDoors"]} admissionData={pageData["admission"]} />
          <Enrollment data={pageData["enrollmentPlan"]} />
        </>
      )}
    </MasterPage>
  );
}