import React, { useState } from "react";
import styles from "./AboutPage.module.css";
import corpusImage from "./images/corpus-01.webp";
import noPhotoPlaceholder from "./images/no-photo-placeholder.svg";
import PageId from "../../shared/PageId";
import PageType from "../../shared/PageType";
import MasterPage from "../MasterPage/MasterPage";
import Block from "../../components/Block/Block";
import Link from "../../components/Link";

/**
 * Компонент страницы колледжа.
 *
 * @returns {Element} Контейнер страницы.
 */
export default function AboutPage() {
  const [pageData, setPageData] = useState(null);

  function Employees() {
    function Contacts({ items }) {
      return (
        <>
          {items?.length > 0 && (
            <div className={styles.cardContacts}>
              {items.map((item, index) => {
                return <div className={styles.cardContactsItem} key={index}>{item}</div>;
              })}
            </div>
          )}
        </>
      );
    }

    function Administration() {
      const data = pageData["administration"];

      function Card({ photoUrl, name, position, contacts }) {
        const url = photoUrl ? process.env.REACT_APP_BASE_URL + photoUrl : noPhotoPlaceholder;

        return (
          <div className={styles.adminCard}>
            <img src={url} alt={name} />
            <div className={styles.adminCardInfo}>
              {name && <div className={styles.adminCardName}>{name}</div>}
              {position && <div className={styles.adminCardPosition}>{position}</div>}
              <Contacts items={contacts} />
            </div>
          </div>
        );
      }

      return (
        <div className={styles.adminGrid}>
          {data?.map((props, index) => {
            return <Card {...props} key={index} />;
          })}
        </div>
      );
    }

    function Divisions() {
      const data = pageData["divisions"];

      function Item({ name, employees }) {
        function MiniCard({ name, position, contacts }) {
          return (
            <div className={styles.employeeCard}>
              {name && <div className={styles.employeeCardName}>{name}</div>}
              {position && <div className={styles.employeeCardPosition}>{position}</div>}
              <Contacts items={contacts} />
            </div>
          );
        }

        return (
          <div className={styles.division}>
            <div className={styles.divisionTitle}>{name}</div>
            <div className={styles.divisionGrid}>
              {employees?.map(({ name, position, contacts }, index) => {
                return <MiniCard icon={null} name={name} position={position} contacts={contacts} key={index} />;
              })}
            </div>
          </div>
        );
      }

      return (
        <>
          {data?.map((props, index) => {
            return <Item {...props} key={index} />;
          })}
        </>
      );
    }

    return (
      <Block>
        <h2 className={"title-2"}>Администрация</h2>
        <div className={styles.employees}>
          <Administration />
          <Divisions />
        </div>
      </Block>
    );
  }
  function History() {
    return (
      <Block>
        <h3 className={"title-2"}>История колледжа</h3>
        <div className={styles.history}>
          <div className={styles.historyImage}>
            <img src={corpusImage} alt={"Главный корпус"}/>
            Главный корпус СПбМРК
          </div>
          <div>
            <p>
              Одно из старейших учебных заведений России, которое готовит специалистов для работы на судах загранплавания
              и на предприятиях рыбной отрасли.
            </p>
            <p>
              Наше учебное заведение было основано в 1913 году как Высшие сельскохозяйственные курсы, а в 1930 году
              Постановлением Наркомата местной промышленности на их базе был учреждён Ленинградский рыбопромышленный
              техникум.
            </p>
            <p>
              В 1953 году техникум был переименован в Ленинградское мореходное училище, а в 1994 году в
              Санкт-Петербургское мореходное училище. В 1997 году училище было реорганизовано в колледж и с тех пор
              носит свое настоящее название.
            </p>
            <p>
              На основании приказа Федерального агентства по рыболовству № 97 от 25 января 2012 года образовательная
              организация реорганизована в форме присоединения в «Санкт-Петербургский морской рыбопромышленный колледж»
              (филиал) федерального государственного бюджетного образовательного учреждения высшего образования <Link
              url={process.env.REACT_APP_BRANCH_URL} isNewTab={true}>«Калининградский государственный технический университет»</Link>.
            </p>
            <p>
              Колледж расположен в одном из самых красивых мест нашего города — в старинном парке на Каменном острове.
            </p>
            <p>
              За годы существования в нашем учебном заведении подготовлено свыше 13 тысяч специалистов, которые работают
              во всех регионах России. В настоящее время колледж готовит специалистов по 7 специальностям. В колледже
              созданы все необходимые условия для учебы и быта курсантов. В их распоряжении более 40 оборудованных
              кабинетов, учебно-тренажерный центр, мультимедийные классы, компьютерные лаборатории с выходом в Интернет,
              спортивно-оздоровительный комплекс, клуб и библиотека.
            </p>
            <p>За время обучения курсанты получают рабочую специальность по избранной профессии.</p>
          </div>
        </div>
      </Block>
    );
  }

  function handleReceiveData(data) {
    setPageData(data);
  }

  return (
    <MasterPage type={PageType.CATEGORY} id={PageId.ABOUT} onReceiveData={handleReceiveData}>
      {pageData && (
        <>
          <Employees />
          <History />
        </>
      )}
    </MasterPage>
  );
}