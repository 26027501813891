import React, { useEffect, useState } from "react";
import styles from "./MasterPage.module.css";
import PageType from "../../shared/PageType";
import Spinner from "../../components/Spinner/Spinner";
import ContentContainer from "../../components/ContentContainer/ContentContainer";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Block from "../../components/Block/Block";
import Link from "../../components/Link";
import useTitle from "../../shared/useTitle";
import useApiData from "../../shared/useApiData";
import Attachment from "../../components/Attachment/Attachment";
import ContentImage from "../../components/ContentImage/ContentImage";
import Warning from "../../components/Warning/Warning";
import Table from "../../components/Table/Table";

/**
 * Компонент мастер-страницы.
 *
 * @param {string} id ID страницы.
 * @param {string} title Заголовок страницы.
 * @param {React.ReactElement} beforeContent Содержимое, отображаемое перед основным контентом.
 * @param {Function} onReceiveData Обратный вызов, вызываемый при получении данных страницы.
 * @param {React.ReactNode} children Вложенные дочерние элементы.
 * @param {string} type Тип страницы. По умолчанию `PageType.PAGE`.
 * @returns {Element} Контейнер страницы.
 */
export default function MasterPage({
    id,
    title,
    beforeContent,
    onReceiveData,
    children,
    type = PageType.PAGE
  }) {
  const [props, setProps] = useState({
    title: title,
    breadcrumbsItems: [{ name: title, url: "" }],
    innerPages: null,
    content: null
  });
  const [requestPath, setRequestPath] = useState(null);
  const data = useApiData(requestPath);

  useTitle(props.title);
  useEffect(() => {
    setRequestPath(id ? `/pages/page?type=${type}&id=${id}` : null);

    if (data) {
      setProps({
        title: data["title"],
        breadcrumbsItems: data["breadcrumbs"],
        innerPages: data["pages"],
        content: data["content"]
      });

      if (onReceiveData) {
        onReceiveData(data["data"]);
      }

      setRequestPath(null);
    }
  }, [type, id, data]);

  function renderContent(content) {
    return content?.map(({ id, type, data }) => {
      if (type === "header") {
        return {
          1: <h1 id={id} dangerouslySetInnerHTML={{ __html: data["text"] }} key={id} />,
          2: <h2 id={id} dangerouslySetInnerHTML={{ __html: data["text"] }} key={id} />,
          3: <h3 id={id} dangerouslySetInnerHTML={{ __html: data["text"] }} key={id} />
        }[data["level"]];
      } else if (type === "paragraph") {
        return <p id={id} dangerouslySetInnerHTML={{__html: data["text"]}} key={id} />
      } else if (type === "warning") {
        return <Warning id={id} title={data["title"]} key={id}>{data["message"]}</Warning>;
      } else if (type === "attaches") {
        const file = data["file"];

        return (
          <Attachment
            name={data["title"]}
            type={file["extension"]}
            size={file["size"]}
            url={file["url"]}
            marginY={true}
            key={id}
          />
        );
      } else if (type === "list") {
        const items = data["items"].map((html, index) => {
          return <li dangerouslySetInnerHTML={{ __html: html }} key={index} />;
        });

        return data["style"] === "ordered" ? <ol id={id} key={id}>{items}</ol> : <ul id={id} key={id}>{items}</ul>;
      } else if (type === "image") {
        const caption = data["caption"];
        const url = process.env.REACT_APP_BASE_URL + data["file"]["url"];

        return <ContentImage url={url} caption={caption} key={id} />;
      } else if (type === "table") {
        return <Table {...data} key={id} />
      }

      return null;
    });
  }

  /**
   * Компонент для отображения ссылок на внутренние страницы раздела.
   *
   * @returns {Element} Контейнер со ссылками на внутренние страницы.
   */
  function InnerPages() {
    function Item({ title, url, isNewTab }) {
      return <Link className={styles.innerPageItem} url={url} isNewTab={isNewTab}>{title}</Link>;
    }

    return (
      <div className={styles.innerPages}>
        <h3 className={"title-3"}>Внутренние страницы раздела</h3>
        <div className={styles.innerPagesLinks}>
          {props.innerPages.map((props, index) => {
            return <Item {...props} key={index} />;
          })}
        </div>
      </div>
    );
  }

  return (
    <>
      {requestPath ? (
        <Spinner />
      ) : (
        <ContentContainer>
          <div className={styles.heading}>
            {props.breadcrumbsItems?.length > 0 && <Breadcrumbs items={props.breadcrumbsItems} />}
            <h1 className={"title-1"}>{props.title}</h1>
          </div>
          {props.innerPages?.length > 0 && <InnerPages />}
          {children}
          {props.content?.length > 0 && (
            <Block>
              {beforeContent}
              {renderContent(props.content)}
            </Block>
          )}
        </ContentContainer>
      )}
    </>
  );
}