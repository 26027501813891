import React from "react";
import styles from "./Attachment.module.css";
import Icon from "../Icon";
import Link from "../Link";

/**
 * Компонент прикреплённого файла.
 *
 * @param {string} name Имя файла.
 * @param {string} type Тип файла.
 * @param {number} size Размер файла в байтах.
 * @param {string} url Ссылка на файл.
 * @param {boolean} marginY Флаг, указывающий на наличие вертикальных отступов. По умолчанию `false`.
 * @returns {Element} Контейнер вложения.
 */
export default function Attachment({ name, type, size, url, marginY = false }) {
  let className = styles.wrapper;

  if (marginY) {
    className += " " + styles.wrapperMargins;
  }

  function formatSize(bytes) {
    if (bytes === 0) {
      return "0 байт";
    } else {
      const k = 1024;
      const sizes = ["байт", "Кб", "Мб", "Гб", "Тб", "Пб", "Эб", "Зб", "Иб"];
      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
    }
  }

  let typeSize = "";

  if (type && size) {
    typeSize += type + ", " + formatSize(size);
  } else if (type && !size) {
    typeSize += type;
  } else if (!type && size) {
    typeSize += formatSize(size);
  }

  return (
    <Link className={className} url={process.env.REACT_APP_BASE_URL + url} isNewTab={true}>
      <div>
        <Icon name={"paperclip"} />
      </div>
      <div className={styles.props}>
        {typeSize !== "" && <span>{typeSize}</span>}
        {name}
      </div>
    </Link>
  );
}